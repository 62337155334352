import { Link, Button, View } from "@adobe/react-spectrum";
import React from "react";
import styled from "styled-components";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { navigate } from "gatsby";
// import LinkOut from "@spectrum-icons/workflow/LinkOut";

const PluginInfoCardWrapper = styled.div`
  max-width: 500px;
  font-size: 16px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
`;
const PluginInfoCard = ({ style, pluginInfo }) => {
  // eslint-disable-next-line global-require, import/no-dynamic-require
  const { Logo } = require(`../icons/plugins/${pluginInfo.logo}`);
  const breakpoints = useBreakpoint();
  return (
    <PluginInfoCardWrapper style={style}>
      <View
        borderWidth="thin"
        borderColor="dark"
        borderRadius="medium"
        backgroundColor="gray-75"
      >
        <div style={{ display: `flex`, flexDirection: `row`, padding: `20px` }}>
          {breakpoints.tabletPortraitUp === true ? (
            <div
              style={{
                flexGrow: `1`,
                display: `flex`,
                flexDirection: `column`,
                paddingTop: `10px`,
                paddingLeft: `10px`,
              }}
            >
              <div style={{ width: "100px", height: "auto" }}>
                <Logo
                  color={
                    pluginInfo.logoColor
                      ? pluginInfo.logoColor
                      : "var(--spectrum-global-color-orange-400)"
                  }
                  opacity="0.3"
                  backgroundcolor="var(--spectrum-global-color-gray-75)"
                />
              </div>
            </div>
          ) : null}
          <div style={{ flexGrow: `1.2` }}>
            <div
              className="title"
              style={
                breakpoints.phoneOnly === true ? { textAlign: `center` } : {}
              }
            >
              <h1 style={{ marginTop: `0`, marginBottom: `0` }}>
                {pluginInfo.title}
              </h1>
              <h3
                style={{ marginTop: `0`, marginBottom: `20px`, opacity: `.5` }}
              >
                v.{pluginInfo.version}
              </h3>
              {breakpoints.phoneOnly === true ? (
                <div
                  style={{ width: "100px", height: "auto", margin: `0 auto` }}
                >
                  <Logo
                    color={
                      pluginInfo.logoColor
                        ? pluginInfo.logoColor
                        : "var(--spectrum-global-color-orange-400)"
                    }
                    opacity="0.3"
                    backgroundcolor="var(--spectrum-global-color-gray-75)"
                  />
                </div>
              ) : null}
              <div>
                <p
                  style={{
                    marginTop: `10px`,
                  }}
                >
                  Photoshop 2021+ only
                  <br />
                  Mac & Windows
                  <br />
                  1 license, 2 computers
                  <br />
                  {/* EULA &nbsp;
                  <Link style={{ color: `white` }}>
                    <a href="/EULA.pdf" target="_blank" rel="noreferrer">
                      <LinkOut
                        size="XS"
                        onPress={() => navigate("/EULA.pdf")}
                      />
                    </a>
                  </Link> */}
                  <Link>
                    <a href="/EULA.pdf" target="_blank" rel="noreferrer">
                      EULA
                    </a>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundColor: `var(--spectrum-global-color-gray-200)`,
            borderBottomLeftRadius: `7px`,
            borderBottomRightRadius: `7px`,
            borderTop: `1px solid var(--spectrum-global-color-gray-400)`,
            paddingTop: `15px`,
            paddingBottom: `15px`,
            paddingLeft: `35px`,
            paddingRight: `35px`,
            display: `flex`,
            justifyContent: `space-between`,
            alignItems: `center`,
          }}
        >
          <span>{pluginInfo.price ? `$${pluginInfo.price}` : "Free"}</span>
          <Button
            type="button"
            variant="cta"
            onPress={() => navigate(pluginInfo.link)}
          >
            {pluginInfo.price ? "Buy" : "Get"}
          </Button>
        </div>
      </View>
    </PluginInfoCardWrapper>
  );
};

export default PluginInfoCard;
