import { Divider } from "@adobe/react-spectrum";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import PluginsLayout from "../../components/PluginsLayout";
import { PluginContext } from "../../contexts/PluginContext";
import ThemeVideo from "../../videos/Floating.mp4";
import FloatingPanelSVG from "../../components/FloatingPanelSVG";
import PluginInfoCard from "../../components/PluginInfoCard";
import SEO from "../../components/seo";
import SchemaORG from "../../data/floating-schema.json";
import pluginsArray from "../../data/plugins.json"

const WellContainer = styled.div`
  /* display: flex; */
  /* flex-direction: column;
  align-items: center; */
  /* position: relative; */
  width: 100%;
  text-align: center;

  .plugin-banner {
    border-radius: 8px;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }

  @media (min-width: 900px) {
    font-size: var(--spectrum-global-dimension-font-size-400) !important;
    .banner-well {
      box-shadow: inset 0 1px 6px rgba(0, 0, 0, 0.2),
        0 1px 0 rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      max-width: 900px;
      margin: 0 auto;
      background-color: var(--spectrum-global-color-gray-75);
      padding-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 40px;
    }
  }
`;

const FloatingPlugin = () => {
  const { FloatingBanner } = useStaticQuery(
    graphql`
      query {
        FloatingBanner: file(
          relativePath: { eq: "highlights/floating-highlight.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    `
  );
  const { activePlugin, setActivePlugin } = useContext(PluginContext);

  useEffect(() => {
    if (activePlugin !== "floating-adjustments") {
      setActivePlugin("floating-adjustments");
    }
  });

  const pluginInfoCardSettings = {
    logo: "Floating-SVG",
    logoColor: "var(--spectrum-global-color-blue-400)",
    title: "Floating Adjustments",
    version: pluginsArray.filter(plugin => plugin.title === "Floating Adj")[0].version,
    price: undefined,
    link:
      "https://adobe.com/go/cc_plugins_discover_plugin?pluginId=573bb5f6&workflow=share",
  };

  return (
    <PluginsLayout>
      <SEO
        title="Floating Adjustments for Photoshop"
        description="Floating Adjustments, Big Floating Adjustment Dialogs plugin for Photoshop"
        image="https://www.cc-extensions.com/img/twitter-floating.jpg"
        slug="plugins/floating-adjustments"
        schema={SchemaORG}
      />

      <WellContainer>
        <div className="banner-well">
          <h1 className="plugin">Floating Adjustments</h1>
          <h2
            className="plugin"
            style={{ color: `var(--spectrum-global-color-blue-400)` }}
          >
            Big Floating Adjustment Dialogs
          </h2>
          <Img
            fluid={FloatingBanner.childImageSharp.fluid}
            className="plugin-banner"
          />
        </div>
      </WellContainer>
      <div style={{ textAlign: `left`, maxWidth: `900px` }}>
        <h3 className="plugin" style={{ marginBottom: `10px` }}>
          In short
        </h3>
        <Divider size="M" />
        <p>
          Create and Modify all kinds of Adjustment Layers (Curves,
          Hue/Saturation, Color Lookup, etc.) as big, floating dialogs.
        </p>

        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          controls
          autoPlay
          muted
          loop
          className="responsive-iframe"
          width="100%"
          style={{ marginBottom: `50px` }}
        >
          <source src={ThemeVideo} type="video/mp4" />
        </video>

        <FloatingPanelSVG />

        <h3 className="plugin" style={{ marginBottom: `10px` }}>
          Get it
        </h3>
        <Divider size="M" />
        <p>
          It&rsquo;s free, it&rsquo;s tiny, it&rsquo;s useful. What not to like.
        </p>
        <PluginInfoCard
          style={{ margin: `50px auto` }}
          pluginInfo={pluginInfoCardSettings}
        />
      </div>
    </PluginsLayout>
  );
};

export default FloatingPlugin;
